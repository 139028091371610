import { createContext, useContext } from 'react';
import theme from './theme';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children, customTheme }) => {
  const mergedTheme = { ...theme, ...customTheme }; // Merge customTheme with the default theme
  return <ThemeContext.Provider value={mergedTheme}>{children}</ThemeContext.Provider>;
};
