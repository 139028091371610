// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubblesContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.bubblesTopBackground {
  position: relative;
  height: 0px;
  top: 0;
  left: 0;
  right: 0;
}

.bubblesTop {
  position: relative;
  width: 1921px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .bubblesTop {
    width: 1200px;
  }
  .bubblesTopBackground {
    height: 40px;
  }
}


@media only screen and (min-width: 1921px) {
  .bubblesTop {
    width: 100%;
    top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/TopClouds.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,YAAY;EACd;AACF;;;AAGA;EACE;IACE,WAAW;IACX,MAAM;EACR;AACF","sourcesContent":[".bubblesContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  overflow: hidden;\n}\n\n.bubblesTopBackground {\n  position: relative;\n  height: 0px;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.bubblesTop {\n  position: relative;\n  width: 1921px;\n  top: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 0;\n  margin: 0;\n  padding: 0;\n}\n\n@media only screen and (max-width: 767px) {\n  .bubblesTop {\n    width: 1200px;\n  }\n  .bubblesTopBackground {\n    height: 40px;\n  }\n}\n\n\n@media only screen and (min-width: 1921px) {\n  .bubblesTop {\n    width: 100%;\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
