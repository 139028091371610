import React, { useState } from 'react';
import mixpanel from '../Services/Mixpanel.js';

/// UI COMPONENTS
import PrimaryButton from '../Components/PrimaryButton';
import Spacer from '../Components/Spacer';
import BottomClouds from '../Components/BottomClouds';
import { Img } from 'react-image';

/// THEME
import { useTheme } from '../Theme/ThemeContext';
import { useH5Style } from '../Theme/ThemeStyles';
import '../Theme/Container.css';

/// NAVIGATION
import CatarotRoute from '../CatarotRoute';
import { useNavigate } from 'react-router-dom';

const QuestionInput = () => {
  const navigate = useNavigate();

  const [question, setQuestion] = useState('');
  const [error, setError] = useState('');
  const isButtonEnabled = question.length > 0 && question.trim() !== '';

  const theme = useTheme();

  const handleInputChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleContinue = () => {
    const trimmedQuestion = question.trim();

    mixpanel.track('continue_clicked', {
      source: 'question_input',
      characters: trimmedQuestion.length,
    });

    if (trimmedQuestion === '' || trimmedQuestion.length < 1) {
      setError('YOU NEED TO ASK THE CAT A QUESTION (AT LEAST 10 CHARACTERS)');
    } else {
      setError('');
      navigate(CatarotRoute.CARD_SELECTION, { state: { question: question } });
    }
  };

  const containerStyle = {
    animation: 'appear 0.5s ease-out',
    maxWidth: '720px',
    padding: '20px',
    margin: 'auto',
    zIndex: 0
  };

  const inputContainerStyle = {};

  const catImageStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const inputStyle = {
    width: '100%',
    minHeight: '200px',
    boxSizing: 'border-box',
    resize: 'none',
    padding: '20px',
    fontSize: '20px',
    fontFamily: theme.fontFamily,
    borderRadius: theme.borderRadius,
    border: '0px',
    boxShadow: theme.shadowColor + ' 0px 2px',
    color: theme.primaryActionBackgroundColor,
    backgroundColor: theme.darkBackground,
  };

  const buttonStyle = {
    color: theme.primaryActionBackgroundColor,
    backgroundColor: theme.darkBackground
  };

  const buttonDisabledStyle = {
    color: theme.primaryActionBackgroundColor + '50',
    backgroundColor: theme.darkBackground + '50'
  };

  const titleStyle = {
    color: theme.primaryActionBackgroundColor,
  };

  const errorStyle = {
    textAlign: 'center',
    color: theme.secondaryTextColor,
    fontSize: '14px',
    fontWeight: '700',
  };

  const bubblesContainer = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '50%',
    zIndex: 1
  };

  return (
    <div className="container" style={containerStyle}>
      <h5 style={{...useH5Style(), ...titleStyle}}>What do you want to ask the cat?</h5>
      <Spacer spaces={3} />
      <div style={inputContainerStyle}>
        <textarea
          value={question}
          onChange={handleInputChange}
          placeholder="Example: Would I find true love?"
          style={inputStyle}
        />
        {error &&
          <>
          <Spacer spaces={1} />
          <div style={errorStyle}>{error}</div>
          </>
        }
      </div>
      <Spacer spaces={2} />
      <PrimaryButton onClick={handleContinue} disabled={!isButtonEnabled} style={buttonStyle} disabledStyle={buttonDisabledStyle}>Pick the cards</PrimaryButton>
      <Spacer spaces={2} />
      <div style={bubblesContainer}>
      <BottomClouds className="firstCloud" color={theme.thirdColor}/>
      <div style={catImageStyle}>
        <Img
          src="/cat_looking_up.svg"
          alt="Floating Image"
          style={{
            height: '75%',
            zIndex: '3',
            position: 'absolute',
            bottom: '10px'
          }}
        />
      </div>
      <BottomClouds className="secondCloud" color={theme.primaryActionBackgroundColor}/>
      <BottomClouds className="thirdCloud" color={theme.fourthColor}/>
      </div>
    </div>
  );
};

export default QuestionInput;
