import React from 'react';
import { useTheme } from '../Theme/ThemeContext';

const Spacer = ({ spaces }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        height: `${parseInt(theme.space, 10) * spaces}px`,
        width: '100%'
      }}
    />
  );
};

export default Spacer;
