import React from 'react';
import { useTheme } from '../Theme/ThemeContext';

const Button = ({ style, disabledStyle, children, disabled, onClick }) => {
  const theme = useTheme();

  const internalStyle = {
    backgroundColor: theme.primaryActionBackgroundColor,
    color: theme.primaryActionTextColor,
    textAlign: 'center',
    padding: '14px',
    fontSize: '24px',
    fontFamily: theme.fontFamily,
    fontWeight: '700',
    border: 'none',
    borderRadius: theme.borderRadius,
    cursor: 'pointer',
    width: '100%',
    boxShadow: theme.shadowColor + ' 0px 2px',
    ...style
  };

  const internalDisabledStyle = {
    alpha: '90%',
    color: theme.primaryActionTextColor + '40',
    cursor: 'not-allowed',
    boxShadow: theme.shadowColor + ' 0px 2px',
    backgroundColor: theme.primaryActionBackgroundColor + '90',
  };

  return (
    <button
    onClick={onClick}
    disabled={disabled}
    style={{ ...internalStyle, ...(disabled ? {...internalDisabledStyle, ...disabledStyle} : {}) }}
    >
    {children}
    </button>
  );
};

export default Button;
