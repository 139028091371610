import React from 'react';
import { useTheme } from '../Theme/ThemeContext';

const TarotCardContent = ({ card }) => {
  const theme = useTheme();

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column', // Set flexDirection to 'column'
    height: '100%',
    width: '100%',
  };

  const backgroundStyle = {
    position: 'relative',
    width: '100%',
    flex: 1,
    backgroundImage: `url('/cards/${card.imageSrc}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center',
    zIndex: 1,
  };

  const titleStyle = {
    position: 'relative',
    backgroundColor: 'white',
    zIndex: 2,
    borderTop: `1.8px solid #2A154C`,
    paddingTop: '6px',
    paddingBottom: '6px',
    width: '100%',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '700',
  };

  return (
    <div style={contentStyle}>
      <div style={backgroundStyle} />
      <p style={titleStyle}>{card.name.en}</p>
    </div>
  );
};

export default TarotCardContent;
