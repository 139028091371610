import React from 'react';
import { useH5Style, useH3Style, useH7Style } from '../Theme/ThemeStyles';
import Spacer from '../Components/Spacer';
import PrimaryButton from '../Components/PrimaryButton';
import CatarotRoute from '../CatarotRoute';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(CatarotRoute.WELCOME);

  const containerStyle = {
    display: 'block',
    padding: '20px',
    textAlign: 'left',
    animation: 'appear 0.5s ease-out',
    height: '100%'
  };

  return (
    <div style={containerStyle}>
    <div style={{ display: 'flex', width: '50%' }}>
    <PrimaryButton onClick={handleBackClick}>Go back</PrimaryButton>
    </div>
    <Spacer spaces={4}/>
    <div style={useH3Style()}>Terms and Conditions</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>Last Updated: 14/01/2024</div>
    <Spacer spaces={4}/>
    <div style={useH5Style()}>Use of the App</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>Third-Party Services: Our app utilizes third-party services, including artificial intelligence models provided by OpenAI, to generate tarot readings. You acknowledge that the tarot readings are generated by these third-party services, and the results should not be considered as professional advice or taken seriously.</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>No Guarantee of Accuracy: The tarot readings provided by the app are for entertainment purposes only. We do not guarantee the accuracy or reliability of the results. Users are encouraged to use their own judgment and discretion when interpreting the tarot readings.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>User Responsibility</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>Interpretation of Results: You understand and agree that the tarot readings are not endorsed or guaranteed by Catarot. The results are generated based on artificial intelligence models, and Catarot is not responsible for the interpretation or consequences of the tarot readings.</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>No Liability: Catarot and its affiliates shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the app or the tarot readings.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Changes to Terms and Conditions</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be reflected on this page.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Contact Us</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>If you have any questions or concerns about our Terms and Conditions, please contact us at admin@catarot.com</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>By accessing and using this mobile application, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our app.</div>
    <div style={useH7Style()}>Thank you for choosing Catarot.</div>
    </div>
  );
};

export default TermsAndConditions;
