import React, { useState, useEffect, useRef } from 'react';
import mixpanel from '../Services/Mixpanel.js';
import TarotCard from '../Components/TarotCard';
import Spacer from '../Components/Spacer';
import './CardSelection.css';
import { useH5Style } from '../Theme/ThemeStyles';
import MajorArcanaTarotCards from '../Tarot/Cards';
import '../Theme/Container.css';
import CatarotRoute from '../CatarotRoute';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';

const createRefsArray = (length) => {
  return Array.from({ length }, () => useRef(null));
};

const getRandomUniqueIndices = (max, count) => {
  const indices = new Set();

  while (indices.size < count) {
    const randomIndex = Math.floor(Math.random() * max);
    indices.add(randomIndex);
  }

  return Array.from(indices);
};

const CardSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const question = location.state?.question || '';

  const [selectedCards, setSelectedCards] = useState([]);
  const containerRef = useRef(null);
  const boxRefs = createRefsArray(12);
  const [cards, setCards] = useState(() => {
    const initialRandomIndices = getRandomUniqueIndices(MajorArcanaTarotCards.length, 12);
    return initialRandomIndices.map((index) => MajorArcanaTarotCards[index]);
  });

  const handleShuffle = () => {
    const fixedCenterX = window.innerWidth / 2;
    const fixedCenterY = window.innerHeight / 2;

    boxRefs.forEach((boxRef, index) => {
      if (boxRef.current) {
        boxRef.current.style.opacity = 0;
      }
    });

    boxRefs.forEach((boxRef, index) => {
      if (boxRef.current) {
        const { x, y, height, width } = boxRef.current.getBoundingClientRect();
        const distanceX = x + width / 2;
        const distanceY = y + height / 2;

        const animation = boxRef.current.animate(
          {
            transform: [
              `translate(${fixedCenterX - distanceX}px,${fixedCenterY - distanceY}px)`,
              'translate(0px)',
            ],
            easing: ['cubic-bezier(0.68,-.55,.265,1.55)'],
            offset: [0, 1],
            opacity: [0, 1],
          },
          {
            delay: ((index + 1) * 700) / 12,
            duration: 1300,
            fill: 'none',
          }
        );

        animation.onfinish = () => {
          boxRef.current.style.opacity = 1;
        };
      }
    });
  };

  useEffect(() => {
    const trimmedQuestion = question.trim();
    if (trimmedQuestion === '' || trimmedQuestion.length < 1) {
      window.location.href = 'https://www.catarot.net';
    } else {
      handleShuffle();
    }
  }, []);

  useEffect(() => {
    mixpanel.track('card_selected', {
      source: 'card_selection',
      count: selectedCards.length
    });
    if (selectedCards.length === 3) {
      const timeoutId = setTimeout(() => {
        const selectedCardsData = selectedCards.map(index => cards[index]);
        navigate(CatarotRoute.LOADING_AD, { state: { question: question, cards: selectedCardsData }});
      }, 1800);

      return () => clearTimeout(timeoutId);
    }
  }, [selectedCards, cards]);

  const theme = useTheme();

  const fullBackgroundStyle = {
    backgroundColor: theme.thirdColor,
  }
  const containerStyle = {
    animation: 'appear 0.5s ease-out',
    maxWidth: '720px',
    padding: '20px',
    margin: 'auto',
  };

  const cardsStyle = {
  };

  const titleStyle = {
    color: theme.darkBackground,
    textShadow: 'none'
  };

  function handleCardClick(index) {
    setSelectedCards((prevSelected) => {
      const updatedSelected = prevSelected.includes(index)
        ? prevSelected.filter((selected) => selected !== index)
        : [...prevSelected, index];
      return updatedSelected;
    });
  }

  return (
    <div style={fullBackgroundStyle}>
    <div className="container" style={containerStyle}>
      <h5 style={{...useH5Style(), ...titleStyle}}>Choose three cards wisely</h5>
      <Spacer spaces={2} />
      <div className="cardsContainer" ref={containerRef}>
        {Array.from({ length: 12 }).map((_, index) => (
          <TarotCard
            card={cards[index]}
            onSelect={() => handleCardClick(index)}
            key={index}
            ref={boxRefs[index]}
          />
        ))}
      </div>
    </div>
    </div>
  );
};

export default CardSelection;
