import React, { forwardRef, useState } from 'react';
import TarotCardContent from './TarotCardContent';
import { useTheme } from '../Theme/ThemeContext';
import './TarotCard.css';

// Inside the TarotCard component
const TarotCard = forwardRef(({ card, onSelect }, ref) => {
  const theme = useTheme();
  const [isCardSelected, setCardSelected] = useState(false);

  const frontBackStyle = {
    boxShadow: theme.primaryTextColor + '80 0px 2px',
    borderRadius: theme.cardRadius,
    overflow: 'hidden',
  };

  const handleSelect = () => {
    if (!isCardSelected) {
      setCardSelected(true);
      onSelect();
    }
  };

  const radiusStyle = {
    borderRadius: '9px',
  }

  return (
    <div ref={ref}>
    <label>
    <input type="checkbox" onClick={handleSelect} disabled={isCardSelected}/>
    <div className="card" style={radiusStyle}>
    <div className="front" style={frontBackStyle}></div>
    <div className="back" style={frontBackStyle}><TarotCardContent card={card}/></div>
    </div>
    </label>
    </div>
  );
});


export default TarotCard;
