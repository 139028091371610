// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardClass {
  width: 120px;
  height: 160px;
}

@media only screen and (min-width: 767px) {
  .cardClass {
    width: 140px;
    height: 210px;
  }
}

@keyframes buttonFlash {
  0% {
    background-color: white;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Screens/Result.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".cardClass {\n  width: 120px;\n  height: 160px;\n}\n\n@media only screen and (min-width: 767px) {\n  .cardClass {\n    width: 140px;\n    height: 210px;\n  }\n}\n\n@keyframes buttonFlash {\n  0% {\n    background-color: white;\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
