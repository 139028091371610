// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;
}

@media only screen and (min-width: 767px) {
  .container {
    min-height: calc(100vh - 40px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Theme/Container.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  min-height: -webkit-fill-available;\n}\n\n@media only screen and (min-width: 767px) {\n  .container {\n    min-height: calc(100vh - 40px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
