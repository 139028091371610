import { useTheme } from './ThemeContext';
import './Animations.css';

export const useH1Style = () => {
  const theme = useTheme();

  return {
    color: theme.titleTextColor,
    textShadow: theme.shadowColor + ' 0px 2px',
    fontSize: '72px',
    lineHeight: '64px',
  };
};

export const useH3Style = () => {
  const theme = useTheme();

  return {
    color: theme.titleTextColor,
    textShadow: theme.shadowColor + ' 0px 2px',
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '2.64169px',
    textTransform: 'uppercase',
  };
};

export const useH4Style = () => {
  const theme = useTheme();

  return {
    color: theme.secondaryTextColor,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '400'
  };
};

export const useH5Style = () => {
  const theme = useTheme();

  return {
    color: theme.titleTextColor,
    textShadow: theme.shadowColor + ' 0px 2px',
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: '700'
  };
};

export const useH6Style = () => {
  const theme = useTheme();

  return {
    color: theme.secondaryTextColor,
    fontSize: '15px',
    lineHeight: '17px',
    fontWeight: '400'
  };
};

export const useH7Style = () => {
  const theme = useTheme();

  return {
    color: theme.secondaryTextColor,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400'
  };
};

export const animateFromTop = {
  animation: 'slideInFromTop 0.5s ease-out'
};

export const animateFromBottom = {
  animation: 'slideInFromBottom 0.5s ease-out'
};

export const floatAnimation = {
  animation: 'floatAnimation 2s ease-in-out infinite'
};
