const MajorArcanaTarotCards = [
  {
    name: {
      en: "The Fool",
      es: "El Loco"
    },
    imageSrc: "the_fool@2x.png"
  },
  {
    name: {
      en: "The Magician",
      es: "El Mago"
    },
    imageSrc: "the_magician@2x.png"
  },
  {
    name: {
      en: "The High Priestess",
      es: "La Papisa"
    },
    imageSrc: "the_high_priestess@2x.png"
  },
  {
    name: {
      en: "The Empress",
      es: "La Emperatriz"
    },
    imageSrc: "the_empress@2x.png"
  },
  {
    name: {
      en: "The Emperor",
      es: "El Emperador"
    },
    imageSrc: "the_emperor@2x.png"
  },
  {
    name: {
      en: "The Hierophant",
      es: "El Sumo Sacerdote"
    },
    imageSrc: "the_hierophant@2x.png"
  },
  {
    name: {
      en: "The Lovers",
      es: "Los Enamorados"
    },
    imageSrc: "the_lovers@2x.png"
  },
  {
    name: {
      en: "The Chariot",
      es: "El Carro"
    },
    imageSrc: "the_chariot@2x.png"
  },
  {
    name: {
      en: "Strength",
      es: "La Fuerza"
    },
    imageSrc: "strength@2x.png"
  },
  {
    name: {
      en: "The Hermit",
      es: "El Ermitaño"
    },
    imageSrc: "the_hermit@2x.png"
  },
  {
    name: {
      en: "Wheel of Fortune",
      es: "La Rueda de la Fortuna"
    },
    imageSrc: "wheel_of_fortune@2x.png"
  },
  {
    name: {
      en: "Justice",
      es: "La Justicia"
    },
    imageSrc: "justice@2x.png"
  },
  {
    name: {
      en: "The Hanged Man",
      es: "El Ahorcado"
    },
    imageSrc: "the_hanged_man@2x.png"
  },
  {
    name: {
      en: "Death",
      es: "La Muerte"
    },
    imageSrc: "death@2x.png"
  },
  {
    name: {
      en: "The Temperance",
      es: "La Templanza"
    },
    imageSrc: "the_temperance@2x.png"
  },
  {
    name: {
      en: "The Devil",
      es: "El Diablo"
    },
    imageSrc: "the_devil@2x.png"
  },
  {
    name: {
      en: "The Tower",
      es: "La Torre"
    },
    imageSrc: "the_tower@2x.png"
  },
  {
    name: {
      en: "The Star",
      es: "La Estrella"
    },
    imageSrc: "the_star@2x.png"
  },
  {
    name: {
      en: "The Moon",
      es: "La Luna"
    },
    imageSrc: "the_moon@2x.png"
  },
  {
    name: {
      en: "The Sun",
      es: "El Sol"
    },
    imageSrc: "the_sun@2x.png"
  },
  {
    name: {
      en: "Judgment",
      es: "El Juicio"
    },
    imageSrc: "judgement@2x.png"
  },
  {
    name: {
      en: "The World",
      es: "El Mundo"
    },
    imageSrc: "the_world@2x.png"
  }
];

export default MajorArcanaTarotCards;
