// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/cards/back@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  perspective: 1000px;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.card {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: all 600ms;
  z-index: 20;
  border: 1.8px solid #2A154C;
  background: black;
}

.card div {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  backface-visibility: hidden;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}

.card .back {
  transform: rotateY(180deg);
}

input {
  display: none;
}

:checked + .card {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/Components/TarotCard.css"],"names":[],"mappings":"AAAA;EAEE,mBAAmB;EAEnB,4BAA4B;EAC5B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,4BAA4B;EAE5B,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EAEX,4BAA4B;EAE5B,qBAAqB;EACrB,WAAW;EACX,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAElB,2BAA2B;EAC3B,yDAAuD;EACvD,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EAEE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,kCAAkC;AACpC","sourcesContent":["label {\n  -webkit-perspective: 1000px;\n  perspective: 1000px;\n  -webkit-transform-style: preserve-3d;\n  transform-style: preserve-3d;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  transform-style: preserve-3d;\n  -webkit-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n  cursor: pointer;\n}\n\n.card {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  -webkit-transform-style: preserve-3d;\n  transform-style: preserve-3d;\n  -webkit-transition: all 600ms;\n  transition: all 600ms;\n  z-index: 20;\n  border: 1.8px solid #2A154C;\n  background: black;\n}\n\n.card div {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  text-align: center;\n  -webkit-backface-visibility: hidden;\n  backface-visibility: hidden;\n  background-image: url('../../public/cards/back@2x.png');\n  background-size: cover;\n  background-position: center;\n}\n\n.card .back {\n  -webkit-transform: rotateY(180deg);\n  transform: rotateY(180deg);\n}\n\ninput {\n  display: none;\n}\n\n:checked + .card {\n  transform: rotateY(180deg);\n  -webkit-transform: rotateY(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
