import React from 'react';
import { Img } from 'react-image'
import PrimaryButton from '../Components/PrimaryButton';
import Spacer from '../Components/Spacer';
import TopClouds from '../Components/TopClouds';
import Sparkles from '../Components/Sparkles';
import '../Theme/Container.css';
import { animateFromBottom, animateFromTop, floatAnimation, useH1Style, useH3Style, useH6Style } from '../Theme/ThemeStyles';
import { useTheme } from '../Theme/ThemeContext';
import CatarotRoute from '../CatarotRoute';
import { useNavigate } from 'react-router-dom';
import mixpanel from '../Services/Mixpanel.js';

const Welcome = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const handleContinueClick = () => {
    mixpanel.track('start_clicked', {
      source: 'welcome'
    });

    navigate(CatarotRoute.QUESTION_INPUT);
  };

  const handleTermsClick = () => {
    mixpanel.track('terms_clicked', {
      source: 'welcome'
    });

    navigate(CatarotRoute.TERMS);
  };

  const handlePrivacyClick = () => {
    mixpanel.track('privacy_clicked', {
      source: 'welcome'
    });

    navigate(CatarotRoute.PRIVACY);
  };

  const imgContainerStyle = {
    flex: 1,
    position: 'relative',
    animation: 'appear 0.5s ease-out',
    textAlign: 'center',
    ...floatAnimation
  };

  const linkSpanStyle = {
    textDecoration: 'underline'
  };

  const containerStyle = {
    maxWidth: '720px',
    padding: '20px',
    margin: 'auto'
  }

  return (
    <div className="container" style={containerStyle}>
    <TopClouds color={theme.darkBackground}/>
    <h3 style={{...useH3Style(), ...animateFromTop, color: theme.primaryActionBackgroundColor}}>Welcome to</h3>
    <Sparkles color='white'><h1 style={{...useH1Style(), ...animateFromTop, color: theme.primaryActionBackgroundColor}}>Catarot</h1></Sparkles>
    <Spacer spaces={3}/>
    <div style={imgContainerStyle}>
    <div style={{
      left: "50%",
      width: "30vh",
      height: "100%",
      position: 'absolute',
      transform: 'translate(-50%, 0)'
    }}>
    <Img
    src='/cat_front.svg'
    alt="Floating Cat"
    style={{
      width: '100%',
      height: '70%',
      zIndex: 4,
      top: '8%',
      position: 'relative',
    }}
    />
    <Img
    src='/cloud.svg'
    alt="Cloud"
    style={{
      width: '100%',
      height: '30%',
      top: '-10%',
      zIndex: 3,
      position: 'relative'
    }}
    />
    </div>

    </div>
    <Spacer spaces={2}/>
    <PrimaryButton style={animateFromBottom} onClick={handleContinueClick}>Start a read</PrimaryButton>
    <Spacer spaces={2}/>
    <h3 style={{...useH6Style(), ...animateFromBottom}}>By starting a tarot read you are accepting our <span onClick={handleTermsClick} style={linkSpanStyle}>Terms and Conditions</span> and agree with our <span onClick={handlePrivacyClick} style={linkSpanStyle}>Privacy Policy</span></h3>
    </div>
  );
};

export default Welcome;
