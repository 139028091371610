// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topSectionBubbles {
  position: relative;
  width: 1921px;
  height: 36px;
  top: 1px;
  transform: translateX(-50%);
  z-index: 1;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.topSectionBubblesContainer {
  width: 100%;
}

@media only screen and (min-width: 1921px) {
  .topSectionBubbles {
    width: 100%;
    height: 36px;
  }
}


@media only screen and (min-width: 767px) {
  .topSectionBubbles {
    left: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/TopSectionBubbles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,QAAQ;EACR,2BAA2B;EAC3B,UAAU;EACV,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;;AAGA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".topSectionBubbles {\n  position: relative;\n  width: 1921px;\n  height: 36px;\n  top: 1px;\n  transform: translateX(-50%);\n  z-index: 1;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\n.topSectionBubblesContainer {\n  width: 100%;\n}\n\n@media only screen and (min-width: 1921px) {\n  .topSectionBubbles {\n    width: 100%;\n    height: 36px;\n  }\n}\n\n\n@media only screen and (min-width: 767px) {\n  .topSectionBubbles {\n    left: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
