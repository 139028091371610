// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardsContainer {
  flex: 1 1;
  width: 45vh;
  height: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Screens/CardSelection.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,kCAAkC;EAClC,mCAAmC;EACnC,cAAc;AAChB","sourcesContent":[".cardsContainer {\n  flex: 1;\n  width: 45vh;\n  height: 100%;\n  margin: auto;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-template-rows: 1fr 1fr 1fr 1fr;\n  grid-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
