// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubblesBottom {
  position: absolute;
  width: 1921px;
  height: 137px;
  box-sizing: border-box;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.firstCloud {
  bottom: 40px;
  z-index: 1;
  transform: translateX(-70%);
}

.secondCloud {
  bottom: 20px;
  z-index: 2;
  transform: translateX(-60%);
}

.thirdCloud {
  z-index: 3;
}


@media only screen and (max-width: 767px) {
  .bubblesBottom {
    width: 1200px;
  }
}

@media only screen and (min-width: 767px) {
  .firstCloud {
    width: 1921px;
    height: 200px;
    transform: translateX(-60%);
  }

  .secondCloud {
    width: 1921px;
    height: 200px;
    transform: translateX(-40%);
  }

  .thirdCloud {
    width: 1921px;
    height: 200px;
  }
}


@media only screen and (min-width: 1500px) {
  .bubblesBottom {
    width: 2881px;
    height: 305px;
    bottom: 0;
  }

  .firstCloud {
    width: 2881px;
    height: 305px;
    bottom: 10px;
    transform: translateX(-60%);
  }

  .secondCloud {
    width: 2881px;
    height: 305px;
    bottom: 20px;
    transform: translateX(-40%);
  }

  .thirdCloud {
    width: 2881px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/BottomClouds.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;;;AAGA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;IACb,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,aAAa;EACf;AACF;;;AAGA;EACE;IACE,aAAa;IACb,aAAa;IACb,SAAS;EACX;;EAEA;IACE,aAAa;IACb,aAAa;IACb,YAAY;IACZ,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,aAAa;IACb,YAAY;IACZ,2BAA2B;EAC7B;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".bubblesBottom {\n  position: absolute;\n  width: 1921px;\n  height: 137px;\n  box-sizing: border-box;\n  bottom: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 0;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\n.firstCloud {\n  bottom: 40px;\n  z-index: 1;\n  transform: translateX(-70%);\n}\n\n.secondCloud {\n  bottom: 20px;\n  z-index: 2;\n  transform: translateX(-60%);\n}\n\n.thirdCloud {\n  z-index: 3;\n}\n\n\n@media only screen and (max-width: 767px) {\n  .bubblesBottom {\n    width: 1200px;\n  }\n}\n\n@media only screen and (min-width: 767px) {\n  .firstCloud {\n    width: 1921px;\n    height: 200px;\n    transform: translateX(-60%);\n  }\n\n  .secondCloud {\n    width: 1921px;\n    height: 200px;\n    transform: translateX(-40%);\n  }\n\n  .thirdCloud {\n    width: 1921px;\n    height: 200px;\n  }\n}\n\n\n@media only screen and (min-width: 1500px) {\n  .bubblesBottom {\n    width: 2881px;\n    height: 305px;\n    bottom: 0;\n  }\n\n  .firstCloud {\n    width: 2881px;\n    height: 305px;\n    bottom: 10px;\n    transform: translateX(-60%);\n  }\n\n  .secondCloud {\n    width: 2881px;\n    height: 305px;\n    bottom: 20px;\n    transform: translateX(-40%);\n  }\n\n  .thirdCloud {\n    width: 2881px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
