import React, { useState } from 'react';
import Welcome from './Screens/Welcome';
import QuestionInput from './Screens/QuestionInput';
import CardSelection from './Screens/CardSelection';
import LoadingAdState from './Screens/LoadingAdState';
import TermsAndConditions from './Screens/TermsAndConditions';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Result from './Screens/Result';
import CatarotRoute from './CatarotRoute';
import { useTheme } from './Theme/ThemeContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  const theme = useTheme();
  const [result, setResult] = useState('');

  return (
    <div className="appContainer" style={{
      backgroundColor: theme.backgroundColor,
      height: '100%'
    }}>
    <div className="app">
    <Router>
    <Routes>
    <Route
    path={CatarotRoute.WELCOME}
    element={<Welcome />}
    />
    <Route
    path={CatarotRoute.QUESTION_INPUT}
    element={<QuestionInput />}
    />
    <Route
    path={CatarotRoute.CARD_SELECTION}
    element={<CardSelection />}
    />
    <Route
    path={CatarotRoute.LOADING_AD}
    element={<LoadingAdState/>}
    />
    <Route
    path={CatarotRoute.RESULT}
    element={<Result/>}
    />
    <Route
    path={CatarotRoute.PRIVACY}
    element={<PrivacyPolicy />}
    />
    <Route
    path={CatarotRoute.TERMS}
    element={<TermsAndConditions />}
    />
    </Routes>
    </Router>
    </div>
    </div>
  );
};

export default App;
