const CatarotRoute = {
  WELCOME: '/',
  QUESTION_INPUT: '/question',
  CARD_SELECTION: '/card-selection',
  LOADING_AD: '/loading-ad',
  RESULT: '/result',
  TERMS: '/terms',
  PRIVACY: '/privacy',
};

export default CatarotRoute;
