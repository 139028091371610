import { useState, useEffect } from "react";
import './TextTyper.css';

export default function TextTyper({
  text = "",
  interval = 105,
  Markup = "span",
  style,
  onFinish
}) {
  const [typedText, setTypedText] = useState("");
  const [preTypedText, setPreTypedText] = useState("");

  const typingRender = (text, updater, updater2, interval, setter, value) => {
    let localTypingIndex = 0;
    let localTyping = "";
    const words = text.split(' ');

    if (words.length > 0) {
      let printer = setInterval(() => {
        if (localTypingIndex < words.length) {
          updater(localTyping);
          localTyping += words[localTypingIndex] + ' ';
          updater2(words[localTypingIndex]);
          localTypingIndex += 1;
        } else {
          localTypingIndex = 0;
          localTyping = "";
          clearInterval(printer);
          setter && setter(value);
          onFinish && onFinish();
        }
      }, interval);
    }
  };

  useEffect(() => {
    typingRender(text, setTypedText, setPreTypedText, interval);
  }, [text, interval, onFinish]);

  return (
    <div>
      <Markup style={style}>
        {typedText}
      </Markup>
      <Markup className="animatedNewWord" key={preTypedText} style={style}>
        {preTypedText}
      </Markup>
    </div>
  );
}
