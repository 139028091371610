import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import mixpanel from '../Services/Mixpanel.js';

// UI Components
import PrimaryButton from '../Components/PrimaryButton';
import Spacer from '../Components/Spacer';
import TarotCardContent from '../Components/TarotCardContent';
import TextTyper from '../Components/TextTyper';
import TopSectionBubbles from '../Components/TopSectionBubbles';
import Sparkles from '../Components/Sparkles';

// Theming
import { useTheme } from '../Theme/ThemeContext';
import { useH5Style, useH7Style } from '../Theme/ThemeStyles';
import '../Theme/Container.css';
import './Result.css'

// Navigation
import CatarotRoute from '../CatarotRoute';
import { useNavigate, useLocation } from 'react-router-dom';

const Result = () => {
  const theme = useTheme();

  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  const location = useLocation();
  const result = location.state?.result || '';
  const selectedCards = location.state?.cards || [];
  const question = location.state?.question || '';
  const elementRef = useRef(null);
  const navigate = useNavigate();

  const filter = (node: HTMLElement) => {
    const exclusionClasses = ['remove-me'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  }

  const handleShare = async () => {
    mixpanel.track('share_clicked', {
      source: 'result',
    });
    await shareFiles("My Tarot Read from Catarot.net");
  };

  const blobFromDom = async () => {
    const content = document.getElementById('container');

    return new Promise(async (resolve) => {
      const canvas = await html2canvas(content, {
        backgroundColor: 'rgb(107, 0, 186)',
        useCORS: true,
        onclone: async function(doc, element) {
          if (!navigator.canShare) {
            const root = doc.getElementById("root");
            root.style.width = "540px";
            root.style.height = "760px";
          }

          const topSectionBubbles = doc.getElementsByClassName("topSectionBubbles");
          for (let i = 0; i < topSectionBubbles.length; i++) {
            topSectionBubbles[i].style.left = "0";
            topSectionBubbles[i].style.width = "100%";
            topSectionBubbles[i].style.transform = 'none';
          }
          element.style.paddingTop = '50px';
          element.style.animation = 'none';
        }
      });
      const blob = await new Promise((blobResolve) => {
        canvas.toBlob(blobResolve);
      });

      resolve(blob);
    });
  };

  const shareFiles = async ({ text }) => {
    try {
      const blob = await blobFromDom();
      const file = new File([blob], 'image.png', { type: "image/png" });

      if (file) {
        if (!navigator.canShare || !navigator.canShare({ files: [file] })) {
          downloadFile(file, 'tarot-read.png');
          return;
        }
      }

      await navigator.share({ text, files: [file] });
    } catch (error) {
      console.log(`Error sharing: ${error}`);
    }
  };

  const downloadFile = (file, fileName) => {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.setAttribute('href', url);
    a.setAttribute('download', fileName);

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleStartOver = () => {
    mixpanel.track('start_over_clicked', {
      source: 'result',
    });
    window.location.href = 'https://www.catarot.net';
  };

  const handleTypeFinished = () => {
    setIsAnimationFinished(true);
  };

  const containerStyle = {
    textAlign: 'left',
    animation: 'appear 0.5s ease-out',
    paddingTop: '20px',
  };

  const contentStyle = {
    maxWidth: '720px',
    width: 'calc(100% - 40px)',
    padding: '0 20px 0 20px',
    margin: '0 auto 0 auto'
  };

  const textTyperContainerStyle = {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  };

  const resultContainerStyle = {
    flex: '1',
    backgroundColor: theme.primaryActionBackgroundColor,
    paddingBottom: '20px',
    marginBottom: '-20px',
    display: 'flex',
    position: 'relative'
  };

  const cardsSectionStyle = {
    backgroundColor: theme.thirdColor,
  };

  const resultStyle = {
    color: theme.darkBackground,
    textShadow: 'none',
  };

  const typeStyle = {
    flex: '1',
  }

  const cardGridContainerStyle = {
    width: '100%',
    display: 'flex',
    gap: '20px',
  };

  const catarotLogoContainerStyle = {
    position: 'absolute',
    maxWidth: '720px',
    width: 'calc(100% - 40px)',
    top: '0px',
  };

  const cardStyle = {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.cardRadius,
    border: `1.8px solid #2A154C`,
    boxShadow: theme.shadowColor + ' 0px 3px',
  };

  const actionContainerStyle = {
    position: 'absolute',
    right: '0',
    top: '16px',
    animation: 'appear 0.5s ease-out',
  };

  const actionContainerHiddenStyle = {
    opacity: 0
  };

  const actionStyle = {
    position: 'relative',
    backgroundColor: theme.darkBackground,
    color: theme.primaryActionBackgroundColor,
    boxShadow: 'none',
    fontSize: '14px',
    padding: '3px',
    height: '28px',
    marginLeft: '8px',
    zIndex: 2
  };

  const shareActionStyle = {
    ...actionStyle,
    width: '70px'
  };

  const startOverActionStyle = {
    ...actionStyle,
    width: '90px',
  };

  const catarotLogoStyle = {
    position: 'absolute',
    right: '0px',
    top: '4px',
    color: theme.darkBackground,
    opacity: '0.3',
  }

  useEffect(() => {
    const trimmedQuestion = question.trim();
    if (trimmedQuestion === '' || trimmedQuestion.length < 1 || selectedCards.length < 3) {
      window.location.href = 'https://www.catarot.net';
    }
  }, []);

  return (
    <div id="container" className="container" style={containerStyle} ref={elementRef}>
    <div style={contentStyle}>
    <div style={catarotLogoContainerStyle} data-html2canvas-ignore="true">
    <div style={isAnimationFinished ? actionContainerStyle : actionContainerHiddenStyle}>
    <PrimaryButton onClick={handleShare} style={shareActionStyle}>
    <Sparkles color='white'>
    Share
    </Sparkles>
    </PrimaryButton>
    <PrimaryButton onClick={handleStartOver} style={startOverActionStyle}>Start over</PrimaryButton>
    </div>
    </div>
    <h4 style={useH7Style()}>You asked</h4>
    <Spacer spaces={1}/>
    <h3 style={useH5Style()}>{question}</h3>
    </div>
    <Spacer spaces={1}/>
    <TopSectionBubbles color={theme.thirdColor}/>
    <div style={cardsSectionStyle}>
    <div style={contentStyle}>
    <h4 style={{...useH7Style(), ...resultStyle}}>Selected cards</h4>
    <Spacer spaces={1}/>
    <div style={cardGridContainerStyle}>
    {[0, 1, 2].map((index) => (
      selectedCards[index] ? (
        <div style={cardStyle} className="cardClass" key={index}>
        <TarotCardContent card={selectedCards[index]} />
        </div>
      ) : (
        <div key={index}></div>
      )
    ))}
    </div>
    </div>
    <Spacer spaces={1}/>
    <TopSectionBubbles color={theme.primaryActionBackgroundColor}/>
    </div>
    <div style={resultContainerStyle}>
    <div style={{...contentStyle, ...textTyperContainerStyle}}>
    <div style={catarotLogoContainerStyle}><h4 style={catarotLogoStyle}>CATAROT.NET</h4></div>
    <Spacer spaces={1}/>
    <h4 style={{...useH7Style(), ...resultStyle}}>Cat's read</h4>
    <Spacer spaces={1}/>
    <h3 style={{...useH5Style(), ...resultStyle, ...typeStyle}}>
    {isAnimationFinished ? (
      <span>
      { result }
      </span>
    ) : (
      <TextTyper text={result} isFinished={isAnimationFinished} onFinish={handleTypeFinished}/>
    )}
    </h3>
    <Spacer spaces={2}/>
    </div>
    </div>
    </div>
  );
};

export default Result;
