import React from 'react';
import { useH5Style, useH3Style, useH7Style } from '../Theme/ThemeStyles';
import Spacer from '../Components/Spacer';
import PrimaryButton from '../Components/PrimaryButton';
import CatarotRoute from '../CatarotRoute';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(CatarotRoute.WELCOME);

  const containerStyle = {
    padding: '20px',
    textAlign: 'left',
    animation: 'appear 0.5s ease-out'
  };

  return (
    <div style={containerStyle}>
    <div style={{ display: 'flex', width: '50%' }}>
    <PrimaryButton onClick={handleBackClick}>Go back</PrimaryButton>
    </div>
    <Spacer spaces={4}/>
    <div style={useH3Style()}>Privacy Policy</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>Last Updated: 14/01/2024</div>
    <Spacer spaces={4}/>

    <div style={useH5Style()}>Welcome to CatTarot!</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect the information you provide while using our services.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Information Collection and Use</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>Questions and Answers: We do not store your questions and answers on our servers. Your interactions with our app, including the questions you ask and the cards you choose, are not saved.</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>Statistical Data: We may collect statistical data to improve our service. This data is aggregated and does not contain any personally identifiable information.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Third-Party Services</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>OpenAI: We use OpenAI as a third-party service to generate results based on the questions and cards you provide. We only send the necessary information for generating results and do not share any private or personal user information.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Changes to this Privacy Policy</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>We may update our Privacy Policy from time to time. Any changes will be reflected on this page.</div>
    <Spacer spaces={2}/>
    <div style={useH5Style()}>Contact Us</div>
    <Spacer spaces={1}/>
    <div style={useH7Style()}>If you have any questions or concerns about our Privacy Policy, please contact us at admin@catarot.com</div>
    <Spacer spaces={2}/>
    <div style={useH7Style()}>By using our app, you agree to the terms outlined in this Privacy Policy.</div>
    <div style={useH7Style()}>Thank you for choosing Catarot.</div>
    </div>
  );
};

export default PrivacyPolicy;
