const theme = {
  titleTextColor: '#FFFFFF',
  primaryActionTextColor: '#000000',
  secondaryTextColor: '#FFFFFF',
  primaryActionBackgroundColor: '#F2FF48',
  secondaryActionBackgroundColor: '#BEC77A',
  shadowColor: '#2A197240',
  backgroundColor: '#6B00BA',
  darkBackground: '#121212',
  thirdColor: '#F674C2',
  fourthColor: '#FF8B1F',
  fontFamily: 'Mukta',
  padding: '20px',
  space: '8px',
  borderRadius: '28px',
  cardRadius: '8px',
};

export default theme;
