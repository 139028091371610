// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/cards/back@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Mukta', helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: '-webkit-fill-available';
}

body::after{
   position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
   content:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@media only screen and (min-width: 767px) {
  body {
    height: 100vh;
  }
}

.app {
  height: 100%;
  margin: auto;
  text-align: center;
  z-index: 1000;
}

#root {
  height: 100% !important
}

h1, h2, h3, h4, h5, h6, h7, img, p {
  margin: 0;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;GACG,iBAAiB,EAAE,OAAO,EAAE,QAAQ,EAAE,eAAe,EAAE,UAAU;GACjE,+CAA0C;AAC7C;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE;AACF;;AAEA;EACE,SAAS;EACT,YAAY;AACd","sourcesContent":["html {\n  margin: 0px;\n  padding: 0px;\n}\n\nbody {\n  margin: 0px;\n  padding: 0px;\n  font-family: 'Mukta', helvetica;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  min-height: '-webkit-fill-available';\n}\n\nbody::after{\n   position:absolute; width:0; height:0; overflow:hidden; z-index:-1;\n   content:url('../public/cards/back@2x.png');\n}\n\n@media only screen and (min-width: 767px) {\n  body {\n    height: 100vh;\n  }\n}\n\n.app {\n  height: 100%;\n  margin: auto;\n  text-align: center;\n  z-index: 1000;\n}\n\n#root {\n  height: 100% !important\n}\n\nh1, h2, h3, h4, h5, h6, h7, img, p {\n  margin: 0;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
